<template>
  <edit-template class="edit" @confirm="submit" :confirm-loading="loading" type="card">
    <edit-card title="教材基础信息">
      <el-form :model="form" ref="form" label-width="120rem" :rules="rules">
<!--        所属学科:select-->
        <el-form-item label="学科" prop="subject_id">
          <el-select v-model="form.subject_id" @change="onSubjectChange">
            <el-option
              v-for="item in subjectData"
              :key="item.id"
              :label="item.subject_name"
              :value="item.id">
            </el-option>
          </el-select>
        </el-form-item>
<!--        应用学段:radio-->
        <el-form-item label="学段" prop="apply_type">
          <el-radio-group v-model="form.apply_type" @change="onTypeChange">
            <el-radio v-for="item in gradeArrMap" :key="item.value" :label="item.value">{{ item.label }}</el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item label="学年" prop="apply_year">
          <el-select v-model="form.apply_year" :disabled="!form.apply_type" @change="onYearChange">
            <el-option
              v-for="item in gradeYearArr"
              :key="item.value"
              :label="item.label"
              :value="item.value">
            </el-option>
          </el-select>
        </el-form-item>
<!--        教材名称:select-->
        <el-form-item label="教材名称" prop="teach_name">
          <el-select
            v-model="form.teach_name"
            :disabled="!form.subject_id || !form.apply_type || !form.apply_year"
            filterable
            remote
            reserve-keyword
            placeholder="请搜索"
            :remote-method="remoteMethod"
            :loading="searchLoading">
            <el-option
              v-for="item in bookNames"
              :key="item.value"
              :label="item.label"
              :value="item.value">
            </el-option>
          </el-select>
        </el-form-item>
      </el-form>
    </edit-card>
    <edit-card title="章节内容">
      <editable-tree
          ref="tree"
          allow-empty
          node-default="章节"
          :max-level="4"
          :allow-drag="allowDrag"
          :allow-drop="allowDrop"
          :level-rule="levelRule"
          :props="defaultProps"
          :data="data">

      </editable-tree>
    </edit-card>
  </edit-template>
</template>

<script>
import EditTemplate from "@/components/pages/Edit";
import EditCard from "@/components/pages/EditCard";
import EditableTree from "@/components/EditableTree";
import { zhGet, zhPost, zh_grade_year } from '@/api'
import { changeOption } from '@/utils'


export default {
  _config: {route: {path: "add", meta: {title: "新增",style:2}}},
  components: {EditTemplate,EditCard,EditableTree},
  data() {
    return {
      loading: false,
      // 所属学科数据
      subjectData:[],
      // 教材名称数据
      bookNames:[],
      defaultProps:{
        label:"article_name",
        children:"article_list"
      },
      levelRule:[
          // 一级子树
          {
            editable:false, // 是否可编辑
            onlyDeleteChild:true, // 是否只删除子节点
            maxLength:15,    // 子节点最大长度, 设为-1 表示不限制
          },
          // 二级子树
          {
            maxLength: 10
          },
          // 三级子树
          {
            maxLength: 5
          },

      ],
      form: {
        subject_id: null,
        apply_type: null,
        teach_version: null,
        teach_name: null,
        apply_year: null
      },
      // 表单验证规则
      rules: {
        subject_id: [
          {required: true, message: '请选择所属学科', trigger: 'change'},
        ],
        apply_type: [
          {required: true, message: '请选择应用学段', trigger: 'change'},
        ],
        teach_version: [
          {required: true, message: '请选择教材版本', trigger: 'change'},
        ],
        teach_name: [
          {required: true, message: '请选择教材名称', trigger: 'change'},
        ],
        apply_year: [
          {required: true, message: '请选择学年', trigger: 'change'},
        ],
      },
      //章节内容数据
      data:[
        {
          article_name: '章节',
          key:this.$tools.GUID(),
          article_list: []
        }
      ],
      gradeArrMap: [],
      gradeYearArr: [],
      searchLoading: false
    }
  },
  watch:{
    "form.subject_id"(val){
      this.setRootName();
    },
    "form.teach_name"(val){
      this.setRootName();
    },
  },
  mounted() {
    this.init();
  },
  methods: {
    onSubjectChange() {
      this.onClearName()
    },
    onTypeChange(val) {
      this.onClearName()
      this.form.apply_year = null
      const idx = this.gradeArrMap.findIndex(item=>item.value===val)
      this.gradeYearArr = this.gradeArrMap[idx].children
      this.$nextTick(()=>{
        this.$refs.form.clearValidate(['apply_year'])
      })
    },
    onYearChange() {
      this.onClearName()
    },
    onClearName(){
      this.form.teach_name = null
      this.bookNames = []
      this.$nextTick(()=>{
        this.$refs.form.clearValidate(['teach_name'])
      })
    },
    remoteMethod(query) {
      if (query) {
        this.searchLoading = true
        const data = { teach_name: query, apply_type: this.form.apply_type, apply_year: this.form.apply_year, subject_id: this.form.subject_id };
        zhGet('/video/condition-version',data).then(res => {
          const list = res || []
          this.bookNames = changeOption({ list, label: 'teach_name', value: 'id' })
        }).finally(() =>this.searchLoading = false)
      } else {
        this.bookNames = []
      }
    },
    init(){
      zhGet('/video/subject').then(res=>{
        this.subjectData = res
      })
      zh_grade_year().then(res=>{
        if (!res.length) return
        this.gradeArrMap = res
        const id = res.find(item=>item.label==="高中").value
        this.form.apply_type = id
        const idx = res.findIndex(item=>item.value===id)
        this.gradeYearArr = this.gradeArrMap[idx].children
      })
    },
    submit() {
      this.$refs.form.validate(flag=>{
        if (flag) {
          this.$tools.DFS(this.data,item=>{
            item.level=item._tier+1;
            if (item.article_list.length){
              item.article_list.forEach((node,index)=>{
                node.order = index;
              })
            }
          },{
            child:"article_list"
          })
          const data = { article_basic_id: this.form.teach_name, article_list: this.data };
          this.loading = true;
          zhPost('/video/add-article',data).then(()=>{
            this.$setPrevOperation("add");
            this.$router.back();
            this.$message.success("添加成功")
          }).finally(()=>this.loading=false);
        }
      })

    },
    // 设置根节点的名称
    setRootName(){
      let subject = (this.subjectData.find(item => item.id === this.form.subject_id) || {}).subject_name||"";
      let book = (this.bookNames.find(item => item.value === this.form.teach_name) || {}).label||"";
      book = book ? `${book}-` : "";
      subject = subject ? `${subject}-` : "";
      this.data[0][this.defaultProps.label] = `${subject} ${book} 章节`
    },
    // 判断节点能否被放置
    allowDrop(draggingNode, dropNode, type) {
      let curParentId = draggingNode.parent.id;
      let parentId = dropNode.parent.id;
      return parentId === curParentId && type !== 'inner';
    },
    // 判断节点能否被拖拽
    allowDrag(draggingNode) {
      return draggingNode.level !== 1;
    }

  }
}
</script>

<style lang="scss" scoped></style>
